<template lang="pug">
  v-container
    v-row
      v-col(cols="12")
        edit-voucher
      v-col(cols="12")
        edit-voucher-detail
</template>
<script>
export default {
  name: 'EditVoucher',
  components: {
    editVoucher: () => import('./Edit'),
    editVoucherDetail: () => import('./details/Index'),
  },
}
</script>